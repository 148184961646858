import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SecondaryTitle from '../SecondaryTitle/SecondaryTitle';
import ProPayment from '../../components/Subscriptions/Pro/ProPayment';
import NitroPayment from '../../components/Subscriptions/Nitro/NitroPayment';
import { useReactToPrint } from 'react-to-print';
import { prepareClassName } from '../../../utils/styles';
import { GetInvoiceNumber } from '../../components/GetInvoiceNumber';
import {
    createTransaction,
    fetchIdSubscription,
    fetchProceed, fetchSubscriptionInfo
} from '../../components/Subscriptions/subscriptionsSlice';
import yourRateIcon from '../../../assets/newLanding/rates/yourRateIcon.svg';
import styles from './styles.module.css';
import ToggleSwitch from "../../components/Subscriptions/ToggleSwitch/ToggleSwitch";
import {useAuth} from "../../../contexts/authContext";
import {login} from "../../../utils/auth";
import StartPayment from "../../components/Subscriptions/Start/StartPayment";

const SELECT_BUTTON_MODE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
};

const Select = (props) => {

};

const SelectButton = (props)  => {
    const { children, mode, onClick, disabled } = props;
    const isPrimary = mode === SELECT_BUTTON_MODE.PRIMARY;

    const className = prepareClassName([
        styles.button,
        isPrimary && styles.buttonPrimary,
    ]);

    return (
        <button className={className} onClick={onClick} disabled={disabled}>{children}</button>
    );
};

const Rate = (props) => {
    const { title, children, price, oldPrice, titleColor, isYourRate, isSelectDisabled, isPrimary, onClick,invoiceClick } = props;
    const { t } = useTranslation();
    const monthly=useSelector(state =>state.subscriptionsSlice.monthly)

    const rootClassName = prepareClassName([styles.rate, isPrimary && styles.ratePrimary]);
    const priceClassName = prepareClassName([styles.price, isPrimary && styles.pricePrimary]);

    return (
        <div className={rootClassName}>
            <div className={styles.rateHeader}>
                <div className={styles.rateTitle} style={{ color: titleColor }}>{title}</div>
                {isYourRate && (
                    <div className={styles.selectedMark}>
                        <div className={styles.selectedMarkText}>{t('Subscriptions.Start.yourChoice')}</div>
                        <img className={styles.selectedMarkIcon} src={yourRateIcon} alt="" />
                    </div>
                )}
            </div>
            <div className={styles.rateText}>
                {children}
            </div>
            <div className={styles.bottom}>
                {(price || oldPrice) && (
                    <div className={priceClassName}>
                        {oldPrice && (
                            <div className={styles.priceItem}>
                                <div className={styles.oldPrice}>
                                    {oldPrice.toLocaleString()}
                                </div>
                                <div>₽</div>
                            </div>
                        )}
                        {price && (
                            <div className={styles.priceItem}>
                                <div className={styles.currentPrice}>
                                    {price.toLocaleString()}
                                </div>
                                <div>₽{!monthly && <>/{t('Subscriptions.year')}</>}</div>
                            </div>
                        )}
                    </div>
                )}
                <SelectButton
                    onClick={onClick}
                    mode={isPrimary ? SELECT_BUTTON_MODE.PRIMARY : SELECT_BUTTON_MODE.SECONDARY}
                    disabled={isSelectDisabled}
                >
                    {t('Subscriptions.btn')}
                </SelectButton>
                <button className='download_btn' onClick={invoiceClick}>
                    {t('Subscriptions.btnDownload')}
                </button>
            </div>
        </div>
    )
};
const PRINT_START_SELECTOR='.print_start'
const PRINT_NITRO_SELECTOR = '.print_nitro';
const PRINT_RPO_SELECTOR = '.print_pro';

const Rates = () => {
    const { isAuth } = useAuth();
    const {t, i18n} = useTranslation()
    const dispatch = useDispatch();
    const invoice = useSelector(state => state.walletSlice.wallet.invoice)
    const invoiceNumber = useSelector(state => state.subscriptionsSlice.invoiceId)
    const paymentId=useSelector(state=>state.subscriptionsSlice.linkId)
    const monthly=useSelector(state =>state.subscriptionsSlice.monthly)
    const userInfo=useSelector(state=>state.authSlice.userInfo)
    const orderId=useSelector(state=>state.subscriptionsSlice.orderId)
    const link=useSelector(state=>state.subscriptionsSlice.link)
    const subscriptionInfo=useSelector(state=>state.subscriptionsSlice.subscriptionInfo)

    const [startActive,setStartActive]=useState(false)
    const [nitroActive,setNitroActive]=useState(false)
    const [proActive,setProActive]=useState(false)

    const [monthPriceStart,setMonthPriceStart]=useState('')
    const [yearPriceStart,setYearPriceStart]=useState('')

    const [monthPriceNitro,setMonthPriceNitro]=useState('')
    const [yearPriceNitro,setYearPriceNitro]=useState('')

    const [monthPricePro,setMonthPricePro]=useState('')
    const [yearPricePro,setYearPricePro]=useState('')

    useEffect(()=>{
        dispatch(fetchSubscriptionInfo())
    },[dispatch])

    useEffect(()=>{
        if (userInfo) {
            if (userInfo?.subscription === null) {
                setStartActive(false)
                setNitroActive(false)
                setProActive(false)
            } else {
                if (userInfo?.subscription.state === 'active' && userInfo?.subscription.type === 'start') {
                    setStartActive(true)
                }
                if (userInfo?.subscription.state === 'active' && userInfo?.subscription.type === 'nitro') {
                    setNitroActive(true)
                }
                if (userInfo?.subscription.state === 'active' && userInfo?.subscription.type === 'pro') {
                    setProActive(true)
                }
            }
        }
    },[userInfo])


    useEffect(()=>{
        if (subscriptionInfo) {
            setMonthPriceStart(subscriptionInfo[0]?.month_price)
            setYearPriceStart(subscriptionInfo[0]?.year_price)
            setMonthPriceNitro(subscriptionInfo[1]?.month_price)
            setYearPriceNitro(subscriptionInfo[1]?.year_price)
            setMonthPricePro(subscriptionInfo[2]?.month_price)
            setYearPricePro(subscriptionInfo[2]?.year_price)
        }
    },[subscriptionInfo])


    useEffect(()=>{
        if (link) {
            return window.location.href=link
        }
    },[link])

    const printStartRate = useReactToPrint({
        content: () => document.querySelector(PRINT_START_SELECTOR),
        documentTitle: `Счет на оплату подписки TONNA Start ${moment().format('DD.MM.yyyy')}`,
        onBeforeGetContent:()=>{
            return new Promise((resolve,reject) => {
                dispatch(createTransaction({ type: "start",monthly:monthly})).then(res=>resolve(res)).catch(err=>reject(err))
            } )
        }
    })

    const printNitroRate = useReactToPrint({
        content: () => document.querySelector(PRINT_NITRO_SELECTOR),
        documentTitle: `Счет на оплату подписки TONNA Nitro ${moment().format('DD.MM.yyyy')}`,
        onBeforeGetContent:()=>{
            return new Promise((resolve,reject) => {
                dispatch(createTransaction({ type: "nitro",monthly:monthly})).then(res=>resolve(res)).catch(err=>reject(err))
            } )
        }
    })

    const printProRate = useReactToPrint({
        content: () => document.querySelector(PRINT_RPO_SELECTOR),
        documentTitle: `Счет на оплату подписки TONNA Pro ${moment().format('DD.MM.yyyy')}`,
        onBeforeGetContent:()=>{
            return new Promise((resolve,reject) => {
                dispatch(createTransaction({ type: "pro",monthly:monthly})).then(res=>resolve(res)).catch(err=>reject(err))
            } )
        }
    })

    const handleSelectStart=()=>{
        if (isAuth) {
            dispatch(fetchIdSubscription({ type: "start",monthly:monthly}))
        } else {
            login()
        }
    }



    const handleSelectNitro = () => {
        if (isAuth) {
            dispatch(fetchIdSubscription({ type: "nitro",monthly:monthly}))
        } else {
            login()
        }
        // printNitroRate();
        // dispatch(increaseInvoiceNumber());
    };

    const handleSelectPro = () => {
        if (isAuth) {
            dispatch(fetchIdSubscription({type: "pro",monthly:monthly}))
        } else {
            login()
        }
        // printProRate();
        // dispatch(increaseInvoiceNumber());
    };

    useEffect(()=>{
        if (paymentId && i18n.language) {
            if (i18n.language === 'ru') {
                dispatch(fetchProceed({id:paymentId,lang:'ru'}))
                       } else {
                          dispatch(fetchProceed({id:paymentId,lang:'en'}))
                }
        }
    },[paymentId])





    return (
        <section className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <SecondaryTitle>{t('Subscriptions.landingTitle')}</SecondaryTitle>
            </div>
            <ToggleSwitch/>
            <div className={styles.listWrapper}>
                <Rate title="Start" invoiceClick={isAuth ? printStartRate : login} isYourRate={startActive} price={monthly ? monthPriceStart : yearPriceStart} oldPrice={monthly ? "" : 68400} onClick={handleSelectStart}>
                    <p>10% {t('Subscriptions.Nitro.first')}</p>
                    <p>30 {t('Subscriptions.Nitro.third')}</p>
                    <p>{t('Subscriptions.Pro.six')}</p>
                </Rate>
                <Rate title="Nitro" invoiceClick={isAuth ? printNitroRate : login} isYourRate={nitroActive} price={monthly ? monthPriceNitro : yearPriceNitro} oldPrice={monthly ? "" : 187200} onClick={handleSelectNitro} titleColor="#45A484">
                    <p>4% {t('Subscriptions.Nitro.first')}</p>
                    <p>100 {t('Subscriptions.Nitro.third')}</p>
                    <p>{t('Subscriptions.Pro.six')}</p>
                    <p>{t('Subscriptions.Nitro.fifth')} {t('Subscriptions.Nitro.fifthStrong')}</p>
                    <p>{t('Subscriptions.Nitro.sixth')}</p>
                </Rate>
                <Rate title="Pro" invoiceClick={isAuth ? printProRate : login} isYourRate={proActive} price={monthly ? monthPricePro : yearPricePro} oldPrice={monthly ? "" : 529200} onClick={handleSelectPro} titleColor="#D95D06">
                    <p>3% {t('Subscriptions.Nitro.first')}</p>
                    <p>{t('Subscriptions.Pro.six')}</p>
                    <p>{t('Subscriptions.Pro.seven')}</p>
                    <p>{t('Subscriptions.Nitro.fifth')} {t('Subscriptions.Pro.fifthStrong')}</p>
                    <p>{t('Subscriptions.Pro.fourth')}</p>
                    <p>{t('Subscriptions.Nitro.sixth')}</p>
                    <p>{t('Subscriptions.Pro.second')}</p>
                    <p>{t('Subscriptions.Pro.first')}</p>
                </Rate>
            </div>
            <StartPayment token={orderId} invoiceNumber={GetInvoiceNumber(invoiceNumber)}/>
            <ProPayment token={orderId} invoiceNumber={GetInvoiceNumber(invoiceNumber)} />
            <NitroPayment token={orderId} invoiceNumber={GetInvoiceNumber(invoiceNumber)} />
        </section>
    );
};

export default Rates;
