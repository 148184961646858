import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import {api} from "../../../Store/api";


const initialState = () => {
  return {
    invoiceNumber: 10,
    linkId:null,
      link:null,
      loading: false,
      monthly:true,
      subscriptionInfo:[],
      orderId:'',
      initData:null,
      isModalVisible:false,
      invoiceId:null
  }
}

export const fetchIdSubscription = createAsyncThunk(
    'subscriptions/fetchIdSubscription',
    async ({monthly,type}) => {
        const data={
        monthly:monthly, type:type
        }
        try {
            const response = await api.post(`/payment/create-transaction`,data)
            return response.data
        } catch (err) {
            console.log(err)
        }
    }
)


export const fetchProceed = createAsyncThunk(
    'subscriptions/fetchProceed',
    async ({id,lang}) => {
        try {
            const response = await api.get(`/payment/${id}/proceed?lang=${lang}`)
             return response.data
            // return window.location.href=response.data.link
        } catch (err) {
            console.log(err)
        }
    }

)

export const fetchSubscriptionInfo = createAsyncThunk(
    'subscriptions/fetchSubscriptionInfo',
    async () => {
        try {
            const response = await api.get(`/public/subscription-info`)
            return response.data

        } catch (err) {
            console.log(err)
        }
    }
)
export const fetchInitTrial = createAsyncThunk(
    'subscriptions/fetchInitTrial',
    async () => {
        try {
            const response = await api.get(`/private/init-trial`)
            return response.data

        } catch (err) {
            console.log(err)
        }
    }
)

export const createTransaction = createAsyncThunk(
    'subscriptions/createTransaction',
    async ({monthly,type}) => {
        const data={
            monthly:monthly, type:type
        }
        try {
            const response = await api.post(`/payment/create-transaction`,data)
            return response.data
        } catch (err) {
            console.log(err)
        }
    }
)

const subscriptionsSlice = createSlice({
  name: 'subscriptionsSlice',
  initialState: initialState(),
  reducers: {
    increaseInvoiceNumber(state) {
      state.invoiceNumber += 1;
    },
      changeMonthlyState(state,action) {
        state.monthly=action.payload
      },
      changeSubscription(state,action) {

      },
        setIsModalVisible(state,action) {
        state.isModalVisible=action.payload
        }
  },
  extraReducers:{
    [fetchIdSubscription.pending]: (state) => {
       state.loading = true
       // state.error.failed = false
     },
     [fetchIdSubscription.fulfilled]: (state, action) => {
       state.loading = false
       state.linkId=action.payload.id
       // state.orderId=action.payload.order_id
       localStorage.setItem('orderId', JSON.stringify(state.orderId))
     },
    [fetchIdSubscription.rejected]: (state) => {
        state.loading = false
     },
      [fetchProceed.pending]: (state) => {
          // state.loading = true
      },
      [fetchProceed.fulfilled]: (state, action) => {
          // state.loading = false
          state.link = action.payload.link
      },
      [fetchProceed.rejected]: (state) => {
          // state.loading = false
      },
      [fetchSubscriptionInfo.pending]: (state) => {
          state.loading = true

      },
      [fetchSubscriptionInfo.fulfilled]: (state, action) => {
          state.loading = false
          state.subscriptionInfo = action.payload
      },
      [fetchSubscriptionInfo.rejected]: (state) => {
          state.loading = false
      },
      [fetchInitTrial.pending]: (state) => {
          state.loading = true

      },
      [fetchInitTrial.fulfilled]: (state, action) => {
          state.loading = false
          state.initData = action.payload
      },
      [fetchInitTrial.rejected]: (state) => {
          state.loading = false
      },
      [createTransaction.pending]: (state) => {
          state.loading = true
      },
      [createTransaction.fulfilled]: (state, action) => {
          state.loading = false
          state.orderId = action.payload.order_id
          state.invoiceId=action.payload.id
      },
      [createTransaction.rejected]: (state) => {
          state.loading = false
      },
   }
});

export const { increaseInvoiceNumber,changeMonthlyState,setIsModalVisible } = subscriptionsSlice.actions

export default persistReducer(
  { key: 'subscriptionsSlice', storage, whitelist: ['invoiceNumber'] },
  subscriptionsSlice.reducer
);
