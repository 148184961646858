import React, {useEffect, useState} from 'react'
import './subscriptions.css'
import Start from "./Start/Start";
import Nitro from "./Nitro/Nitro";
import Pro from "./Pro/Pro";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import ToggleSwitch from "./ToggleSwitch/ToggleSwitch";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../Loader";
import {fetchProceed, fetchSubscriptionInfo, setIsModalVisible} from "./subscriptionsSlice";
import {Modal} from "antd";
import history from "../../../utils/history";

function Subscriptions() {
    const dispatch=useDispatch()
    const {t,i18n} = useTranslation()
    const loading = useSelector(state=>state.subscriptionsSlice.loading)
    const userInfo=useSelector(state=>state.authSlice.userInfo)
    const [startActive,setStartActive]=useState(false)
    const [nitroActive,setNitroActive]=useState(false)
    const [proActive,setProActive]=useState(false)
    const [startDate,setStartDate]=useState(new Date())
    const [proDate,setProDate]=useState(new Date())
    const [nitroDate,setNitroDate]=useState(new Date())
    const paymentId=useSelector(state=>state.subscriptionsSlice.linkId)

    const link=useSelector(state=>state.subscriptionsSlice.link)

    const isModalVisible=useSelector(state=>state.subscriptionsSlice.isModalVisible)

    useEffect(()=>{
        dispatch(fetchSubscriptionInfo())
    },[dispatch])


    useEffect(()=>{
        if (userInfo) {
            if (userInfo?.subscription === null) {
                setStartActive(false)
                setNitroActive(false)
                setProActive(false)
            } else {
                if (userInfo?.subscription.state === 'active' && userInfo?.subscription.type === 'start') {
                    setStartActive(true)
                    setStartDate(userInfo.subscription.until)
                }
                if (userInfo?.subscription.state === 'active' && userInfo?.subscription.type === 'nitro') {
                    setNitroActive(true)
                    setNitroDate(userInfo.subscription.until)
                }
                if (userInfo?.subscription.state === 'active' && userInfo?.subscription.type === 'pro') {
                    setProActive(true)
                    setProDate(userInfo.subscription.until)
                }
            }
        }
    },[userInfo])

    useEffect(()=>{
        if (paymentId && i18n.language) {
            if (i18n.language === 'ru') {
                dispatch(fetchProceed({id:paymentId,lang:'ru'}))
            } else {
                dispatch(fetchProceed({id:paymentId,lang:'en'}))
            }
        }
    },[paymentId])

    useEffect(()=>{
        if (link) {
        return window.location.href=link
    }
    },[link])

    const goToSub=()=>{
        history.push('/subscriptions')
        dispatch(setIsModalVisible(false))
    }


    return (
        <section className="Subscriptions main">
            <div className="content-wrapper"  style={{alignItems: 'center'}}>
                {loading
                    ? <Loader />
                    : <>
                        <Helmet>
                            <title>{t('Subscriptions.PageTitle')}</title>
                        </Helmet>
                    <h1 className="title mobileMargin">{t('Subscriptions.PageTitle')}</h1>
                    <div style={{maxWidth: '599px', marginBottom: '60px'}} className='subscription_description'>
                {t('Subscriptions.pageDescription')}
                    </div>
                    <ToggleSwitch/>
                    <div className='subscription_card_container'>
                    <Start active={startActive} date={startDate} />
                    <Nitro active={nitroActive} date={nitroDate}/>
                    <Pro active={proActive} date={proDate}/>
                    </div>
                    </>
                }

            </div>
            <Modal closeIcon={<div className="close"></div>}
                   title={t('Subscriptions.landingTitle')}
                   okText={"OK"}
                   visible={isModalVisible}
                   onOk={goToSub}
                   cancelButtonProps={{ style: { display: 'none' } }}>
                <div style={{ paddingBottom: '10px' }} className="card_info">{t('Subscriptions.modalText')}</div>
            </Modal>
        </section>
    )
}

export default Subscriptions
