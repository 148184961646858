import React, { useEffect, useState } from 'react';
import GoBack from '../../components/GoBack/GoBack';
import {Button, Input, Modal, Tooltip} from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  disableOffer,
  fetchOffer,
  fetchRequests,
  fetchTrade,
  finishTrade,
  requestTrade,
  resetOffer,
} from './offerSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import history from '../../../utils/history';
import { fetchBeneficiary } from '../../Verification/verificationSlice';
import RespondingUser from './components/RespondingUser';
import { priceMask, stringToNum } from '../../components/masks';
import GoTermsOfUse from '../../components/TermsOfUse/GoTermsOfUse';
import Form from 'antd/lib/form/Form';
import UploadDocs from './components/UploadDocs';
import { fetchFiles } from '../offersSlice';
import { getConfig } from '../../../configs/config';
import Credit from './Credit/Credit';
import {ReactComponent as ChatSVG} from '../../../assets/icon/chat.svg'
import tinkoffImg from '../../../assets/icon/tinkoff.svg';
import dengiImg from '../../../assets/icon/dengi.svg';
import sberImg from '../../../assets/icon/sber.svg';
import psbfactoringImg from '../../../assets/icon/psbfactoring.svg';
import Ruble from '../../../assets/icon/₽.svg'
import StepsBar from "../components/StepsBar";
import shareSvg from "../../../assets/icon/share.svg";
import {OKShareButton, TelegramShareButton, TwitterShareButton, VKShareButton, WhatsappShareButton} from "react-share";
import {ReactComponent as VKIcon} from "../../../assets/icon/social/vk.svg";
import {ReactComponent as WhatsappIcon} from "../../../assets/icon/social/whatsapp.svg";
import {ReactComponent as TwitterIcon} from "../../../assets/icon/social/twitter.svg";
import {ReactComponent as TelegramIcon} from "../../../assets/icon/social/telegram.svg";
import {ReactComponent as OKIcon} from "../../../assets/icon/social/ok.svg";
import ArrowSVG from "../../../assets/icon/arrowLong.svg";
import '../offers.css'
import {Helmet} from "react-helmet";
// import shareSvg from '../../../../assets/icon/share.svg'



function Offer() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isShareModalVisible, setIsShareModalVisible] = useState(false)
  const [cardNum, setCardNum] = useState(null)
  const [phoneNum, setPhoneNum] = useState(null)
  const [bik, setBik] = useState(null)
  const [bankName, setBankName] = useState(null)
  const [reqPrice, setReqPrice] = useState(null)
  const [invoiceNum, setInvoiceNum] = useState(null)
  const [swift, setSwift] = useState(null)
  const { t } = useTranslation()
  const [modalInfo, setModalInfo] = useState({ title: t('respond.modalTitle'), ok: t('respond.agree') })
  const id = useParams().id
  const isTrade = useParams().entity === 'trade' ? true : false
  const beneficiary = useSelector(state => state.verificationSlice.beneficiary)
  const files = useSelector(state => state.offersSlice.files)
  const dispatch = useDispatch()
  const offerData = useSelector(state => state.offerSlice.offerData)
  const tradeData = useSelector(state => state.offerSlice.offerData.offer_info)
  const offerInfo = tradeData ? tradeData : offerData
  const isOfferLoaded = useSelector(state => state.offerSlice.loaded)
  const isBeneficiaryLoaded = useSelector(state => state.verificationSlice.fetchBeneficiary)
  const isLoaded = isOfferLoaded && isBeneficiaryLoaded.loaded
  const formatDate = moment(offerInfo.date).local().format('DD.MM.YYYY')
  const link = window.location.href
  const status = offerInfo.status
  const isMine = isTrade ? offerData.is_customer : offerInfo.is_mine
  const role = isTrade ? isMine ? t('respond.buyer') : t('respond.supplerr') : null
  const carrierContact = offerData.carrier_contact
  const requests = useSelector(state => state.offerSlice.requests)
  const [offerType, setOfferType] = useState()
  const [statusInfo, setStatusInfo] = useState({})
  const tradeState = isTrade ? offerData.state : offerInfo.trade_state
  const [btnInfo, setBtnInfo] = useState({})
  const [isCopy, setIsCopy] = useState(false)
  const shareMessage = t('NewApplication.shareMessage')
  const respondingUsers = offerInfo.upload && requests.length > 0 && !tradeState ? <div style={{ marginTop: 35 }}>{requests.map((request) => {
    return <RespondingUser data={{
      hasMoney: request.has_money,
      requestId: request.id,
      offerId: request.offer_id,
      upload: offerInfo.upload.address.city ? offerInfo.upload.address.city : offerInfo.upload.address.state,
      unload: offerInfo.unload.address.city ? offerInfo.unload.address.city : offerInfo.unload.address.state,
      beneficiaryData: request.beneficiary_info.beneficiary_data,
      inn: request.beneficiary_info.inn,
      phoneNum: request.carrier_contact,
      createdAt: request.created_at,
      price: request.price
    }} />
  })}</div> : null

  const userInfo = useSelector(state => state.authSlice.userInfo)

  const [isBlur,setIsBlur]=useState(false)

  useEffect(()=>{
    if (userInfo) {
      if (userInfo?.subscription === null) {
        setIsBlur(true)
      }
    }
  },[userInfo])

  const getAdress = (adressObj) => {
    return `${adressObj.city ? adressObj.city + ',' : adressObj.state ? adressObj.state + ',' : ''}
     ${adressObj.county ? adressObj.county + ',' : ''} ${adressObj.road ? adressObj.road : ''} 
      ${adressObj.house_number ? adressObj.house_number + ',' : ''} ${adressObj.postcode ? adressObj.postcode : ''}`
  }
  const goOffers = () => {
    history.push('/offers')
  }

  const showModal = () => {
    if (modalInfo.title === t('respond.modalTitle')) {
      if (isMine , phoneNum , cardNum , reqPrice , invoiceNum , bik , bankName) {
        setIsModalVisible(true)
      }
    }
   else {
      setIsModalVisible(true)
    }
  }

  const handleOk = () => {
    setIsModalVisible(false)
    if ((!tradeState || tradeState === 'created') && !isMine) {
      dispatch(requestTrade({ id, card: stringToNum(cardNum), phoneNum, bik, bankName, invoiceNum, swift , price: stringToNum(reqPrice) })).then((params) => {
        dispatch(fetchOffer({ id }))
      })
    } else if (isMine && (!tradeState || tradeState === 'created')) {
      dispatch(
        disableOffer({ id })
      ).then((params) => {
        history.push('/offers/my')
      })
    }
    else if (isMine && isTrade && tradeState !== 'finished') {
      dispatch(finishTrade({ id })).then((params) => {
        history.push('/trades')
      })
    }

  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const goVerify = () => {
    history.push(`/verification/${'tbank'}`)
  }

  const showShareModalModal = () => {
    setIsShareModalVisible(true)
  }

  const handleShareModalCancel = () => {
    setIsShareModalVisible(false)
  }
  const handleShareModalOk = () => {
    setIsShareModalVisible(false)
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link)
    setIsCopy(true)
  }

  useEffect(() => {
    dispatch(fetchBeneficiary({ verifyType: 'tbank' }))
    if (isTrade) {
      dispatch(fetchTrade({ id }))
      dispatch(fetchFiles({ id }))
    } else {
      dispatch(fetchOffer({ id }))
      dispatch(fetchRequests({ id })).then(() => {
        setPhoneNum(null)
        setBik(null)
        setBankName(null)
        setInvoiceNum(null)
      })
    }

    return () => {
      dispatch(resetOffer())
    }

  }, [dispatch, id, isTrade])
  useEffect(() => {
    if (id) {
      if (status === 'disabled' && isMine) {
        setStatusInfo({
          class: 'status-cancel',
          text: t('respond.notActive')
        })

        setBtnInfo({
          class: 'btn green',
          text: t('respond.activate')
        })
      } else if (status === 'enabled') {
        setStatusInfo({
          class: 'status-paid',
          text: t('Applications.waitingFor')
        })
        if (!isMine && (!tradeState || tradeState === 'created'))
          setBtnInfo({
            class: 'btn',
            text: t('Applications.reply')
          })
      }
      if (tradeState === 'in_progress') {
        setStatusInfo({
          class: 'status-in-processing',
          text: t('respond.performed')
        })

      }
      if (tradeState === 'finished') {
        setStatusInfo({
          class: 'status-success',
          text: t('respond.fulfilled')
        })
        setBtnInfo({
          class: 'hide',
          text: ''
        })

      }
      if (status === 'enabled') {
        if (tradeState && tradeState !== 'created') {
          if (!isTrade || !isMine) {
            setBtnInfo({
              class: 'hide',
              text: ''
            })
          } else if (tradeState !== 'finished') {
            setBtnInfo({
              class: 'btn green',
              text: t('respond.complete')
            })
            setModalInfo({
              title: t('respond.completeOffer'),
              ok: t('respond.yes')
            })
          }

        } else if (isMine) {
          setBtnInfo({
            class: 'btn red',
            text: t('respond.deactivate')
          })
          setModalInfo({
            title: t('respond.deactivateWarning'),
            ok: t('respond.yes')
          })
        }
      }

      if (offerInfo.offer_type === 'passengers') {
        setOfferType(t('NewApplication.transportationOfPassengers'))
      }
      if (offerInfo.offer_type === 'delivery') {
        setOfferType(t('NewApplication.supplyOfGoods'))
      }
      else {
        setOfferType(t('NewApplication.cargoTransportation'))
      }
    }
    return () => {
      setBtnInfo({ class: 'hide', text: null })
      setStatusInfo({ class: 'hide', text: null })
      setOfferType(null)
    }

  }, [id, isMine, isTrade, offerData, offerInfo.offer_type, status, t, tradeData, tradeState])

  const banks = [
    {
      name: 'psbfactoring',
      id: 3,
      percent: 'от 9%',
      money: 'индивидуально',
      image: psbfactoringImg,
    },
    {
      name: 'sberbank',
      id: 2,
      percent: 'от 17%',
      money: 'от 500 000 ₽',
      image: sberImg,
    },
    {
      name: 'tinkoff',
      id: 1,
      percent: 'от 24%',
      money: 'до 15 000 000 ₽',
      image: tinkoffImg,
    }
    /*
    ,
    {
      name: 'dengi',
      id: 2,
      percent: '36%',
      money: 'от  1 000 000 ₽',
      image: dengiImg,
    }*/
  ]

  return (
    <section class='main'>
      <div className="content-wrapper">
        <GoBack prevPageName={isTrade ? t('deals') : t('Applications.applicationManagement')} />
        {isLoaded && offerInfo.upload ?
          <div>
            <div className="offer_header">
              <Helmet>
                <title>{offerType}</title>
                <meta name="description" content={`Взять груз из ${offerInfo.upload?.address.country} в ${getAdress(offerInfo.unload?.address)} от ${formatDate}`} />
              </Helmet>
              <h1 style={{ marginBottom: 5 }} className="title">{offerType}</h1>
              <span style={{ display: 'flex', alignItems: 'center', marginBottom: '40px' }}>
                <div style={{ marginRight: '12px' }} className={`${statusInfo.class}`}>
                  {statusInfo.text}
                </div>
                {statusInfo.class === 'status-in-processin' || statusInfo.class === 'status-paid'
                  ? <Tooltip title={t('respond.rubleTooltip')}><img style={{ marginRight: '10px' }} src={Ruble} alt="" /></Tooltip>
                  : ''
                }
                <div className="date">
                  {`${formatDate}`}
                </div>
                <div className='chat-icon' onClick={() => history.push(`/chat/${offerInfo.owner_id}`)}>
                        <ChatSVG />
                    </div>
              </span>
              <StepsBar status={statusInfo}/>
            </div>
            <div className="offer_body">
              <Modal closeIcon={<div className='close'>{t('NewApplication.close')}</div>} title={modalInfo.title} okText={modalInfo.ok} cancelText={t('NewApplication.rejection')}
                visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              </Modal>
              <div style={{ maxWidth: '600px' }}>
                <div className="offer_body_item">{isTrade ? `${t('respond.deal')}` : `${t('respond.requestNumber')}`}: <span>{id}</span></div>
                <div className="offer_body_item">{t('NewApplication.country')}: <span>{offerInfo.upload?.address.country}</span></div>
                <div className="offer_body_item offer_body_item_centered"><div className="offer_body_item_width">{t('NewApplication.loadingPoint')}:</div> <span>{getAdress(offerInfo.upload?.address)}</span></div>
                <div className="offer_body_item">{t('NewApplication.unloadingPoint')}: <span>{getAdress(offerInfo.unload?.address)}</span></div>
                <div className="offer_body_item">{t('NewApplication.startDate')}: <span>{formatDate}</span></div>
                <div className="offer_body_item">{t('NewApplication.additionalConditions')}:<span className={isBlur && 'offer_blur'}>{offerInfo.terms !== 'string' ? offerInfo.terms : ''}</span>
                </div>
                <div className="offer_body_item">{t('Applications.price')}:<span className={isBlur && 'offer_blur'}>{priceMask(offerInfo.price)} ₽ </span></div>
                {role ? <div className="offer_body_item">{'Я'}:<span>{role}</span></div> : null}
                <div
                  className="offer_body_item">{t('NewApplication.customerContacts')}: <span>
                    <Tooltip placement="topLeft" title="Позвонить"><a style={{ color: '#615ffb' }} href={`tel:${offerInfo.contact}`} className={isBlur && 'offer_blur'}>{offerInfo.contact}</a></Tooltip>
                  </span>
                </div>
                <div className="offer_body_item">{t('NewApplication.share')}
                  <div onClick={() => { showShareModalModal() }} style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '14px' }}>
                    <img src={shareSvg}  alt='Поделиться'/>
                  </div>
                </div>
              </div>
              {(tradeState === 'in_progress' || tradeState === 'finished') && isTrade
                  ? null
                  : <div>
                    <p className="credit-text">{t('NewApplication.creditFunds')}</p>
                    {banks.map((item, index) => {
                      return (
                          <Credit key={item.id} bank={item} isLast={banks.length !== index + 1} />
                      );
                    })}
                  </div>
              }
              <div>
                {/*{carrierContact ? <div*/}
                {/*  className="offer_body_item">{t('NewApplication.carrierContacts')}: <span>*/}
                {/*    <Tooltip placement="topLeft" title="Позвонить"><a style={{ color: '#615ffb' }} href={`tel:${carrierContact}`}>{carrierContact}</a></Tooltip>*/}
                {/*  </span>*/}
                {/*</div>*/}
                {/*  : null*/}
                {/*}*/}
                {beneficiary ? <div className='offer_body_item'>{t('respond.supplierr')}:</div> : ''}

                {tradeState === 'in_progress' || tradeState === 'finished' && isTrade
                    ? <div className="card">
            <span className="card_header">
                <div style={{ display: 'flex', alignItems: 'center' }} className="offer-number">
                  {/*<div style={{ marginLeft: '10px' }} className={`${statusInfo.class}`}>{statusInfo.text}</div>*/}
                  {/*{statusInfo.class === 'status-in-processin' || statusInfo.class === 'status-paid'*/}
                  {/*    ? <Tooltip title={t('respond.rubleTooltip')}><img style={{marginLeft: '10px'}} src={Ruble} alt=""/></Tooltip>*/}
                  {/*    : ''*/}
                  {/*}*/}
                  {beneficiary.beneficiary_data?.first_name} {beneficiary.beneficiary_data?.last_name}

                </div>
                <span style={{ display: 'flex', alignItems: 'center', }} className="date">{formatDate}
                </span>
            </span>
                      <h2 className="card_title">{t('invoice.inn')}: {beneficiary.inn}</h2>
                      <div className="card_footer">
                <span className="card_info">
                    <span className="card_info_item"><span>{t('Applications.route')}: </span>{offerInfo.upload.address.city ? offerInfo.upload.address.city : offerInfo.upload.address.state}<img
                        style={{ margin: '0 2px', height: 19, width: 33 }} src={ArrowSVG} alt="" />{offerInfo.unload.address.city ? offerInfo.unload.address.city : offerInfo.unload.address.state}</span>
                  {/*<span className="card_info_item"><span>{t('NewApplication.customerContacts')}: </span> {phoneNumber}</span>*/}
                  {carrierContact ? <span className="card_info_item"><span>{t('NewApplication.carrierContacts')}: </span> {carrierContact}</span> : null}
                  {/*{role ? <span className="card_info_item"><span>{t('NewApplication.myRole')}: </span> {role}</span> : null}*/}

                  {/*<span className="card_info_item"><span>{t('NewApplication.price')}: </span> {offerInfo.price} ₽</span>*/}
                </span>
                      </div>
                    </div>
                    : null
                }


                {(tradeState === 'in_progress' || tradeState === 'finished') && isTrade ? <>
                  <UploadDocs id={id} />
                  <div className='documents-wrapper'>
                    <span>{t('files')}</span>
                    {files.map((file) => {
                      return <a target='_blank'
                        href={getConfig().baseUrl + '/api/v1/public/file/' + file.id}
                      >
                        <div>{file.file_name}</div>
                      </a>
                    })}
                  </div>
                </> : null}
                {(!tradeState || tradeState === 'created') && !isMine && isLoaded ?
                  <div>
                    <GoTermsOfUse btnName={btnInfo.text} />
                    <Tooltip trigger="focus" title={t('respond.warning')}>
                      <Form name='cardNumForm' style={{textAlign: 'center',display:"flex",flexDirection:"column",alignItems:'center'}}>
                        {/* <Input value={cardNum} required style={{ maxWidth: '400px', height: '40px', marginBottom: '15px' }} onInput={handleCardChange}
                          placeholder={t('respond.enterCard')} /> */}
                          <Input value={reqPrice} required className='offer_input' onInput={(e) => { setReqPrice(priceMask(e.target.value)) }}
                                 placeholder={t('respond.placeholderPrice')} />
                        <Input type='number' className='offer_input' value={phoneNum} required onInput={(e) => { setPhoneNum(e.target.value) }}
                          placeholder={t('respond.placeholderContactNumber')} />
                        <Tooltip title={t('respond.bancAccExample')}>
                            <Input value={invoiceNum} className='offer_input' required onInput={(e) => { setInvoiceNum(e.target.value) }}
                             placeholder={t('respond.placeholderBankAccount')} />
                        </Tooltip>
                        <Input value={bik} className='offer_input' required onInput={(e) => { setBik(e.target.value) }}
                          placeholder={t('respond.placeholderBic')} />
                        <Input value={bankName} className='offer_input' required onInput={(e) => { setBankName(e.target.value) }}
                          placeholder={t('respond.placeholderBankName')} />
                        <Input value={swift} className='offer_input' onInput={(e) => { setSwift(e.target.value) }}
                               placeholder={t('respond.swift')} />
                      </Form>
                    </Tooltip>
                  </div>
                  : null}
              </div>
              <div style={{ marginTop: 40, marginBottom: 30 }} className="button_container">
                <button form='cardNumForm' onClick={showModal} className={btnInfo.class}>{btnInfo.text}</button>
              </div>
              {respondingUsers}
              <Modal closeIcon={<div className="close"></div>}
                title={t('Verification.verification')}
                okText={t('Wallet.verify')}
                cancelText={t('NewApplication.close')}
                visible={!beneficiary && isLoaded && !isMine}
                onCancel={goOffers}
                onOk={goVerify}
              >
                <div style={{ paddingBottom: '10px' }} className="card_info">{t('Verification.performerVerification')}</div>
              </Modal>
              <Modal closeIcon={<div className='close'>{t('NewApplication.close')}</div>} title={t('NewApplication.share')} okText={'OK'} cancelText={t('NewApplication.rejection')}
                     visible={isShareModalVisible} onOk={handleShareModalOk} onCancel={handleShareModalCancel}>
                <span style={{ display: 'flex' }}> <Input disabled={true} value={link} /> <Button disabled={isCopy} onClick={copyToClipboard} style={{ width: '60px', marginLeft: '5px' }} >{isCopy ? t('NewApplication.copied') : t('NewApplication.copy')}</Button></span>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '18px 0' }}>
                  <VKShareButton
                      url={link}
                      title={shareMessage}>
                    <VKIcon
                        style={{ height: '40px', width: '40px' }} />
                  </VKShareButton>
                  <WhatsappShareButton
                      url={link}
                      title={shareMessage}>
                    <WhatsappIcon
                        style={{ height: '40px', width: '40px' }} />
                  </WhatsappShareButton>
                  <TwitterShareButton
                      url={link}
                      title={shareMessage}>
                    <TwitterIcon
                        style={{ height: '40px', width: '40px' }} />
                  </TwitterShareButton>
                  <TelegramShareButton
                      url={link}
                      title={shareMessage}>
                    <TelegramIcon
                        style={{ height: '40px', width: '40px' }} />
                  </TelegramShareButton>
                  <OKShareButton
                      url={link}
                      title={shareMessage}>
                    <OKIcon
                        style={{ height: '40px', width: '40px' }} />
                  </OKShareButton>
                </div>
              </Modal>
            </div>
          </div>
          : <Loader />}
      </div>


    </section>
  )
}

export default Offer
