import history from './utils/history'
import './App.css'
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import './i18n'
import {NavLink, Route, Router, Switch} from 'react-router-dom'
import NewOffer from './components/Offers/NewOffer/NewOffer'
import Offers from './components/Offers/Offers'
import SelectLocation from './components/Maps/SelectLocationMap/SelectLocationMap'
import Offer from './components/Offers/Offer/Offer'
import CargoMap from './components/Maps/CargoMap/CargoMap'
import TermsOfUse from './components/components/TermsOfUse/TermsOfUse'
import Subscriptions from './components/components/Subscriptions/Subscriptions'
import Verification from './components/Verification/Verification'
import Wallet from './components/Wallet/Wallet'
import NotFound from './components/NotFound/NotFound'
import Invoice from './components/components/Invoice'
import Offerta from './components/Offerta'
import UserAgreement from './components/UserAgreement'
import {Button, notification} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import Header from './components/components/Header/Header'
import Menu from './components/components/Menu/Menu'
import {t} from 'i18next'
import './theme/colors.css';
import './theme/geometry.css';
import PersonalData from './components/PersonalData'
import FrameworkContract from './components/FrameworkContract'
import LogIn from "./components/LogIn/LogIn";
import Notifications from "./components/Notifications/Notifications";
import FrameworkSupplyContract from "./components/FrameworkSupplyContract";
import siteMap from "./components/siteMap";
import Chats from "./components/Chats/Chats";
import CurrentChat from "./components/Chats/CurrentChat";
import Landing from "./components/Landing/Landing";
import Plan from "./components/Plan";
import keycloak from "./Keycloak";
import Loader from "./components/components/Loader";
import {AuthContext} from './contexts/authContext';
import Footer from './components/components/Footer/Footer';
import ScrollRestoration from './components/ScrollRestoration/ScrollRestoration';
import SuccessNitro from "./components/components/Subscriptions/SuccessNitro/SuccessNitro";
import SuccessPro from "./components/components/Subscriptions/SuccessPro/SuccessPro";
import HelperComponent from "./widget/helperPopup/HelperComponent";

function App() {
    const [keycloakInstance, setKeycloakInstance] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [keycloakReady, setKeycloakReady] = useState(false);
    const cookieNotificationOpenedRef = useRef(false);
    const close = () => {
        localStorage.setItem('cookieAccept', true)
    }
    const openNotification = () => {
        const key = `open${Date.now()}`
        const btn = (
            <Button style={{backgroundColor: '#615ffb', borderColor: '#615ffb'}} type="primary" size="small"
                    onClick={() => {
                        notification.destroy(key)
                        close()
                    }}>
                {t('cookieOk')}
            </Button>)

        notification.open({
            message: 'COOKIE',
            description: t('cookie'),
            btn,
            key,
            duration: 0,
            onClose: close,
        });

        cookieNotificationOpenedRef.current = true;
    }

    useEffect(() => {
        // Initialize Keycloak when the component mounts

        if (!localStorage.getItem('cookieAccept')) {
            openNotification();
        }
    }, []);

    useEffect(() => {
        const initKeycloak = async () => {
            try {
                await keycloak.init({onLoad: 'check-sso', checkLoginIframe: false});
                setKeycloakInstance(keycloak);
                setAuthenticated(keycloak.authenticated);
                setLoading(false)
            } catch (error) {
                console.error('Authentication error:', error);
                setAuthenticated(false);
                setLoading(false)
            }
        };
        initKeycloak()
        if (!localStorage.getItem('cookieAccept') && !cookieNotificationOpenedRef.current) {
            openNotification();
        }
    }, []);

    useLayoutEffect(() => {
        keycloak.onReady = () => {
            setKeycloakReady(true);
        };
    }, []);

    if (!keycloakReady) {
        return <Loader/>;
    }

    return (
        <AuthContext.Provider value={{isAuth: authenticated}}>
            <div className="App">
                {loading ? <Loader/> :
                    <>
                        <Router history={history}>
                            <ScrollRestoration>
                                <Switch>
                                    <Route exact path="/" component={Landing}/>
                                    <Route>
                                        <>
                                            <Header/>
                                            <Switch>
                                                <Route exact path="/newoffer" component={NewOffer}/>
                                                <Route path="/newoffer/selectlocation" component={SelectLocation}/>
                                                <Route path="/:entity(offers|trades)" component={Offers}/>
                                                <Route path="/:entity(offer|trade)/:id?" component={Offer}/>
                                                <Route path="/map/:cords?/:id?" component={CargoMap}/>
                                                <Route path="/terms" component={TermsOfUse}/>
                                                <Route path="/offerta" component={Offerta}/>
                                                <Route path="/agreement" component={UserAgreement}/>
                                                <Route path="/frameworkContract" component={FrameworkContract}/>
                                                <Route path="/frameworkSupplyContract"
                                                       component={FrameworkSupplyContract}/>
                                                <Route path="/subscriptions" component={Subscriptions}/>
                                                <Route path="/verification/:category?" component={Verification}/>
                                                <Route path="/wallet" component={Wallet}/>
                                                <Route path='/invoiceTets' component={Invoice}/>
                                                <Route path='/personalData' component={PersonalData}/>
                                                <Route path='/plan' component={Plan}/>
                                                <Route path='/auth' component={LogIn}/>
                                                <Route path='/notifications' component={Notifications}/>
                                                <Route path='/chats' component={Chats}/>
                                                <Route path='/chat/:id?' component={CurrentChat}/>
                                                <Route path='/sitemap' component={siteMap}/>
                                                <Route path={'/sitemap.xml'} component={siteMap}/>
                                                <Route path="/success/nitro" component={SuccessNitro}/>
                                                <Route path="/success/pro" component={SuccessPro}/>
                                                {/*<Route path='/upload' component={UploadDocs} />*/}
                                                <Route path="*" component={NotFound}/>
                                            </Switch>
                                            <Footer/>
                                        </>
                                    </Route>
                                </Switch>
                                <Menu/>
                                <HelperComponent/>
                            </ScrollRestoration>
                        </Router>
                    </>
                }
            </div>
        </AuthContext.Provider>
    );
};

export default App;
