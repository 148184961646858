import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { fetchOffersForUnauthorized } from '../offersSlice';
import Offer from '../components/Offer'
import { EmptyListPlaceholder } from '../components/EmptyListPlaceholder';

const DEFAULT_REQUESTS = [];

export const OffersForUnauthorized = () => {
    const dispatch = useDispatch();
    const requests = useSelector(state => state.offersSlice.requests);
    const searchValue = useSelector(state => state.offersSlice.searchValue);
    const preparedSearchValue = searchValue.trim().toLowerCase();
    const preparedRequests = requests ?? DEFAULT_REQUESTS;

    // Поиск по аналогии с PublicOffers
    const filteredRequests = useMemo(() => {
        return preparedSearchValue === ''
            ? preparedRequests.filter((request) => request.unload.address.city?.toLowerCase().includes(preparedSearchValue))
            : preparedRequests;
    }, [preparedRequests, preparedSearchValue]);

    useEffect(() => {
        dispatch(fetchOffersForUnauthorized());
    }, []);

    return (
        <section>
            {filteredRequests.length ? filteredRequests.map((offer) => <Offer key={offer.id} data={offer} forceBlur />) : <EmptyListPlaceholder />}
        </section>
    );
};
