import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {fetchUserInfo} from "../../../../Store/authSlice";
import {useDispatch} from "react-redux";
import history from "../../../../utils/history";
import {useAuth} from "../../../../contexts/authContext";
import {prepareClassName} from "../../../../utils/styles";
import {fetchBeneficiary} from "../../../Verification/verificationSlice";
import {fetchInitTrial,setIsModalVisible} from "../subscriptionsSlice";


const BeneficiaryCard=()=>{
    const {isAuth}=useAuth()
    const {t} = useTranslation()
    const dispatch=useDispatch()
    const beneficiary = useSelector(state=> state.verificationSlice.beneficiary)
    const [customerName, setCustomerName] = useState("")
    const [inn, setInn] = useState("")
    const [subscriptionType, setSubscriptionType] = useState('')
    const [formattedDate,setFormattedDate]=useState('')
    const [isNull,setIsNull]=useState(false)
    const [nitroColor,setNitroColor]=useState(false)
    const [proColor,setProColor]=useState(false)
    const [notActive,setNotActive]=useState(false)
    const userInfo = useSelector(state => state.authSlice.userInfo)
    const initTrialData=useSelector(state=>state.subscriptionsSlice.initData)

    useEffect(()=>{
        if (isAuth) {
            dispatch(fetchUserInfo())
            dispatch(fetchBeneficiary({ verifyType: 'tbank' }))
        }
    },[isAuth])

    useEffect(()=>{
        if (initTrialData) {
            dispatch(fetchUserInfo())
            setIsNull(false)
        }
    },[initTrialData])


    useEffect(()=>{
        if (userInfo || initTrialData) {
            if (userInfo?.subscription === null) {
                setIsNull(true)
                let date = new Date()
                const options = { month: 'numeric', day: 'numeric',year:'numeric' }
                let formattedTime = date.toLocaleString('ru-Ru',options)
                setFormattedDate(formattedTime)
            } else {
                setIsNull(false)
                let date = new Date(userInfo?.subscription.until)
                const options = { month: 'numeric', day: 'numeric',year:'numeric' }
                let formattedTime = date.toLocaleString('ru-Ru',options)
                setFormattedDate(formattedTime)
                setSubscriptionType((userInfo?.subscription.type.charAt(0).toUpperCase() + userInfo?.subscription.type.slice(1)))
                if (userInfo?.subscription.type === 'nitro') {
                    setNitroColor(true)
                }
                if (userInfo?.subscription.type === 'pro') {
                    setProColor(true)
                }
                if (userInfo?.subscription.state !== 'active') {
                    setNotActive(true)
                }
            }
        }
    },[userInfo,initTrialData])


    useEffect(() => {
        if (beneficiary) {
            if (beneficiary.beneficiary_data) {
                let customerData = beneficiary.beneficiary_data
                let inn = beneficiary.inn
                setCustomerName(customerData.name ? customerData.name : `${customerData.last_name} ${customerData.first_name} ${customerData.middle_name}`)
                setInn(inn)
            } else {
                setCustomerName('')
                setInn('')
            }
        }
    }, [beneficiary])

    const redirectToWallet=()=>{
        if (isNull) {
        dispatch(fetchInitTrial())
        history.push('/subscriptions')
        } else {
            history.push('/subscriptions')
            dispatch(setIsModalVisible(true))
        }
    }


    const subscriptionClass = prepareClassName([nitroColor && 'color_nitro',proColor && 'color_pro']);

    return (<>
        {isAuth ? <div className='subscription_card_info'>
                <div className='subscription_card_info_title_block'>
                    <div className='subscription_card_info_title'>{beneficiary?.beneficiary_data ? <>{customerName}</> : t('Subscriptions.verification') }</div>
                    <div className='subscription_card_info_title inn'>{beneficiary?.beneficiary_data ? <> {t('Subscriptions.inn')} {inn}</>: t('Subscriptions.usePlatform')}</div>
                </div>
                <div className='subscription_card_info_date_block '>
                    <div className='subscription_card_info_date'>
                        <b>{isNull ? <>{t('Subscriptions.btnStart')}</> : <div className={subscriptionClass}>{subscriptionType}</div>}</b>
                        {!notActive ? <span className='info_date'>{isNull ?<>{t('Subscriptions.freeDays')}</>:<> {t('Subscriptions.until')} {formattedDate}</>}</span> : <div className='not_active'>{t('Subscriptions.notActive')}</div>}
                    </div>
                    <button className="improve_btn" onClick={redirectToWallet}>{!isNull ? t('Subscriptions.btnImprove') : t('Subscriptions.btnStart')}</button>
                </div>
            </div> :
            <div className='subscription_card_info'>
                <div className='subscription_card_info_title_block'>
                    <div className='subscription_card_info_verification'>{t('Subscriptions.verification')}</div>
                    <div className='subscription_card_info_title inn'>{t('Subscriptions.usePlatform')}</div>
                </div>
                <div className='subscription_card_info_date_block ' >
                    <div className='subscription_card_info_date' >
                        <b>{"Start"}</b>
                        <span className='info_date'>{t('Subscriptions.freeDays')}</span>
                    </div>
                    <button className="improve_btn" onClick={redirectToWallet}>{ t('Subscriptions.btnStart')} </button>
                </div>
            </div>}
    </>)
}


export default BeneficiaryCard
