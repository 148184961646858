import React from 'react';
import helperSVG from '../../assets/icon/helperAlex.svg'
import styles from './helperPopup.module.css'

const TelegramHelper = ({popupVisible, setPopupVisible}) => {
    return (
        <>
            {popupVisible && <div className={styles.backdrop} onClick={() => setPopupVisible(false)}></div>}
            <div className={styles.helper_popup} style={{display: popupVisible ? true : "none"}}>
                <div className={styles.helper_head}>
                    <img src={helperSVG} alt=""/>
                    <div>
                        <span>Алексей</span>
                        <span className={styles.helper_status}>Менеджер платформы TONNA</span>
                    </div>
                </div>
                <div className={styles.helper_description}>
                    Добрый день! Меня зовут Алексей, я менеджер платформы TONNA. Пожалуйста, нажмите на кнопку ниже и я
                    отвечу на ваши вопросы в Telegram
                </div>
                <button onClick={() => window.open('https://t.me/tonnahelpbot')} className='btn'>
                    отправить сообщение
                </button>
                <div onClick={() => setPopupVisible(false)} className={styles.helper_closeButton}></div>
            </div>
        </>
    );
};

export default TelegramHelper;