import React, {useEffect, useCallback, useState} from 'react'
import '../../../i18n'
import './header.css'
import '../../../components/Home/home.css'
import {Link, NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {ReactComponent as NotificationsSVG} from '../../../assets/icon/notifications.svg';
import {ReactComponent as CrownSVG} from '../../../assets/icon/crown.svg';
import {ReactComponent as ChatSVG} from '../../../assets/icon/chats.svg';
import {ReactComponent as DocumentSVG} from '../../../assets/icon/document.svg';
import {ReactComponent as MapSVG} from '../../../assets/icon/map.svg'
import {ReactComponent as WalletSVG} from '../../../assets/icon/wallet.svg'
import {ReactComponent as NewOfferSVG} from '../../../assets/icon/plus.svg'
import {ReactComponent as PartnersSVG} from '../../../assets/icon/partners.svg'
import {ReactComponent as ExitSVG} from '../../../assets/icon/Exit.svg'
import authorizedBurger from '../../../assets/newLanding/header/authorizedBurgerMenu.svg'
import {ReactComponent as CrossIcon} from '../../../assets/icon/dark__cross__icon.svg'
import styles from "../../Home/BurgerMenu.module.css"
import burgerStyles from '../../Landing/Hero/styles.module.css'
import { logoutUser } from '../../../utils/auth';
import BeneficiaryCard from "../Subscriptions/BeneficiaryCard/BeneficiaryCard";
import {useAuth} from "../../../contexts/authContext";
import AuthorizedMenu from "../../Landing/Hero/AuthorizedMenu/AuthorizedMenu";
import BurgerMenu from "../../Landing/Hero/BurgerMenu/BurgerMenu";

function Header() {
    const {i18n} = useTranslation()
    const {t} = useTranslation()
    const { isAuth } = useAuth();
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const handleMenuOpen = () => {
        setMenuIsOpen(!menuIsOpen)
    }

    const changeLang = useCallback((e) => {
        i18n.changeLanguage(e.target.value)
    })

    const lang = window.navigator.language

    useEffect(() => {
        document.title = t('docTitle')
        if (lang === "en-US") {
            i18n.changeLanguage('en')
        }
        else if (lang === "ru-RU") {
            i18n.changeLanguage('ru')
            }
        else if (lang === "zh-CH") {
             i18n.changeLanguage('zh')
            }
        // console.log(document.cookie)
    }, [lang])

    return (
        <header className="header">
            <div className='header_wrapper'>
                <Link to='/'>
                    <div className="logo">TONNA<sup>&reg;</sup></div>
                </Link>
                <div className='header-nav'>
                    <select name="lang" id="lang" value={i18n.language} onChange={changeLang} className='header-select'>
                        <option value="ru">Ru</option>
                        <option value="en">En</option>
                        <option value="zh">中</option>
                    </select>
                    <div className='header-nav_desktop'>
                        <Link className='item' activeClassName='menu_item-active' exact to='/notifications'>
                            <NotificationsSVG style={{width: '24px', height: '22px'}} className='menu-svg'/>
                            <span>{t("Menu.notifications")}</span>
                        </Link>
                        <NavLink className='item' activeClassName='menu_item-active' exact to='/newoffer'>
                            <NewOfferSVG style={{width: '22px', height: '25px'}} className='offer-svg'/>
                            <span>{t("Menu.newRequest")}</span>
                        </NavLink>
                        {/*<NavLink className='item' activeClassName='menu_item-active' exact to='/'>*/}
                        {/*  <HomeSVG className='menu-svg' />*/}
                        {/*  <span>{t("Menu.home")}</span>*/}
                        {/*</NavLink>*/}
                        <NavLink className='item' activeClassName='menu_item-active' to='/offers'>
                            <DocumentSVG className='menu-svg'/>
                            <span>{t("Menu.requests")}</span>
                        </NavLink>
                        <NavLink className='item' activeClassName='menu_item-active' to='/map'>
                            <MapSVG className='menu-svg'/>
                            <span>{t("Menu.map")}</span>
                        </NavLink>
                        <NavLink className='item' activeClassName='menu_item-active' to='/chats'>
                            <ChatSVG style={{width: '24px', height: '22px', fill: '#000000'}} className='menu-svg'/>
                            <span>{t('Lending.chat')}</span>
                        </NavLink>
                        <div className={burgerStyles.rightSection} style={{marginLeft: '30px'}}>
                        {/*<div className={burgerStyles.authMenuHorizontalMenu}><AuthorizedMenu /></div>*/}
                        <div className={burgerStyles.authMenuBurgerMenu}><BurgerMenu img={authorizedBurger}/></div>
                        </div>
                        {/*{*/}
                        {/*    menuIsOpen ? (*/}
                        {/*        <CrossIcon*/}
                        {/*            onClick={handleMenuOpen}*/}
                        {/*            style={{*/}
                        {/*                height: '50px',*/}
                        {/*                fill: '#fff',*/}
                        {/*            }}*/}
                        {/*            className="home-menu-svg"*/}
                        {/*        />*/}
                        {/*    ) : (<MenuSVG*/}
                        {/*            onClick={handleMenuOpen}*/}
                        {/*            style={{*/}
                        {/*                height: '50px',*/}
                        {/*                fill: '#fff',*/}
                        {/*            }}*/}
                        {/*            className="home-menu-svg"*/}
                        {/*        />*/}
                        {/*    )*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    menuIsOpen && <div className={styles.burgerContainer}>*/}
                        {/*        <Link to="/wallet" className={styles.flexCenter}>*/}
                        {/*            <WalletSVG className={styles.menuIng}/>*/}
                        {/*            <span className={styles.burgerMenuText}>*/}
                        {/*                {t('Menu.wallet')}*/}
                        {/*            </span>*/}
                        {/*        </Link>*/}
                        {/*        <Link to="/subscriptions" className={styles.flexCenter}>*/}
                        {/*            <CrownSVG className={styles.menuIng}/>*/}
                        {/*            <span className={styles.burgerMenuText}>{t('Lending.subscriptions')}</span>*/}
                        {/*        </Link>*/}
                        {/*        <Link*/}
                        {/*            to="/subscriptions"*/}
                        {/*            target="_blank"*/}
                        {/*            className={styles.flexCenter}>*/}
                        {/*            <PartnersSVG className={styles.menuIng}/>*/}
                        {/*            <span className={styles.partnersText}>{t('Menu.partners')}</span>*/}
                        {/*        </Link>*/}
                        {/*        {isAuth && (*/}
                        {/*            <div className={styles.exitItem} onClick={logoutUser}>*/}
                        {/*                <ExitSVG className={styles.menuIng}/>*/}
                        {/*                <span className={styles.burgerMenuText}>{t('Lending.exit')}</span>*/}
                        {/*            </div>*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>
                </div>
            </div>
            <div className={styles.cardContainer}>
                {isAuth && <BeneficiaryCard/>}
            </div>
        </header>
    )
}

export default Header
