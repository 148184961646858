import React,{useState} from 'react'
import './toggleSwitch.css'
import {useTranslation} from "react-i18next";
import {changeMonthlyState} from "../subscriptionsSlice";
import {useDispatch} from "react-redux";

const SwitchOptions={
    option1:'Месяц',
    option2:'Год'
}

const ToggleSwitch=()=>{
    const dispatch=useDispatch()
    const {t} = useTranslation()
    const [active,setActive]=useState(SwitchOptions.option1)

    const handleSwitchClick=(option,monthlyState)=>{
        dispatch(changeMonthlyState(monthlyState))
        setActive(option)
    }

    return <div className="toggleSwitch_container">
        <div className='toggle_border' style={{left:active === SwitchOptions.option2 ? "50%" : null}}></div>
            <div className="toggleItem" style={{backgroundColor:active === SwitchOptions.option1 ? '#FFF' : "transparent",
           }} onClick={()=>handleSwitchClick(SwitchOptions.option1,true)}>
                <div className="toggleSwitch_text" style={{color:active === SwitchOptions.option1 ? "#6A62F4" : "#A6A6BF"}}>{t('Subscriptions.toggleSwitch.month')}</div>
            </div>
            <div className={"toggleItem"} style={{backgroundColor:active === SwitchOptions.option2 ? "#FFF" : "transparent",
           }} onClick={()=>handleSwitchClick(SwitchOptions.option2,false)}>
            <div className="toggleSwitch_text" style={{color:active === SwitchOptions.option2 ? "#6A62F4" : "#A6A6BF"}}>{t('Subscriptions.toggleSwitch.year')}</div>
        </div>
    </div>
}


export default ToggleSwitch