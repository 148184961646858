import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../../../../utils/auth';
import { useAuth } from '../../../../contexts/authContext';
import burgerMenuIcon from '../../../../assets/newLanding/header/burgerMenu.svg';
import tariffsBlackIcon from '../../../../assets/newLanding/header/tariffsBlack.svg';
import chatBlackIcon from '../../../../assets/newLanding/header/chatBlack.svg';
import newRequestBlackIcon from '../../../../assets/newLanding/header/newRequestBlack.svg';
import requestsBlackIcon from '../../../../assets/newLanding/header/requestsBlack.svg';
import mapBlackIcon from '../../../../assets/newLanding/header/mapBlack.svg';
import walletBlackIcon from '../../../../assets/newLanding/header/walletBlack.svg';
import logoutBlackIcon from '../../../../assets/newLanding/header/logoutBlack.svg';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';

const MenuItem = (props) => {
    const { href, label, iconSrc, onClick } = props;

    const Component = !href ? 'a' : Link;

    return (
        <Component className={styles.menuItem} to={href} onClick={onClick}>
            <img className={styles.menuItemIcon} src={iconSrc} alt="" />
            <div className={styles.menuItemText}>{label}</div>
        </Component>
    );
};

const BurgerMenu = ({img}) => {
    const { isAuth } = useAuth();
    const [isShow, setIsShow] = useState(false);
    const optionsWrapperRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                setIsShow(false);
            }
        };

        const handleClick = (event) => {
            if (optionsWrapperRef.current && event.target && !optionsWrapperRef.current.contains(event.target)) {
                setIsShow(false);
            }
        };

        window.addEventListener('keydown', handleEscKey);
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('keydown', handleEscKey);
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const handleButtonClick = (event) => {
        event.stopPropagation();
        setIsShow((prev) => !prev);
    };

    return (
        <div className={styles.wrapper}>
            <button className={styles.button} onClick={handleButtonClick}>
                <img src={img ? img : burgerMenuIcon} alt="" />
            </button>
            {isShow && (
                <div className={styles.menu} ref={optionsWrapperRef}>
                    <MenuItem iconSrc={tariffsBlackIcon} label={t('Menu.tariffs')} href="/subscriptions" />
                    <MenuItem iconSrc={chatBlackIcon} label={t('Menu.chats')} href="/chats" />
                    <MenuItem iconSrc={newRequestBlackIcon} label={t('Menu.newRequest')} href="/newOffer" />
                    <MenuItem iconSrc={requestsBlackIcon} label={t('Menu.requests')} href="/offers" />
                    <MenuItem iconSrc={walletBlackIcon} label={t('Menu.wallet')} href="/wallet" />
                    <MenuItem iconSrc={mapBlackIcon} label={t('Menu.map')} href="/map" />
                    {isAuth && <MenuItem iconSrc={logoutBlackIcon} label={t('Lending.exit')} onClick={logoutUser} />}
                </div>
            )}
        </div>
    );
};

export default BurgerMenu;
