import React, {useEffect, useState} from 'react';

import {useTranslation} from "react-i18next";
import ProPayment from "./ProPayment";
import {useReactToPrint} from "react-to-print";
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'

import {createTransaction, fetchIdSubscription,increaseInvoiceNumber} from '../subscriptionsSlice';
import {GetInvoiceNumber} from '../../GetInvoiceNumber';
import ActiveCheck from "../../../../assets/newLanding/rates/activeCheck.svg";

const Pro = ({active,date}) => {
    const {t,i18n} = useTranslation()
    const dispatch = useDispatch()
    const invoiceNumber = useSelector(state => state.subscriptionsSlice.invoiceId)
    const monthly=useSelector(state =>state.subscriptionsSlice.monthly)
    const subscriptionInfo=useSelector(state=>state.subscriptionsSlice.subscriptionInfo)
    const orderId=useSelector(state=>state.subscriptionsSlice.orderId)


    const [commission,setCommission]=useState('')
    const [monthPrice,setMonthPrice]=useState('')
    const [yearPrice,setYearPrice]=useState('')

    const [formattedDate,setFormattedDate]=useState('')

    useEffect(()=>{
        const until = new Date(date)
        const options = { month: 'long', day: 'numeric' }
        if (i18n.language === 'en') {
            const formattedDate = until.toLocaleString(['en-US'],options)
            setFormattedDate(formattedDate)
        }
        if (i18n.language === 'ru') {
            const formattedDate = until.toLocaleString(['ru-Ru'],options)
            setFormattedDate(formattedDate)
        }
        if (i18n.language === 'zh') {
            const formattedDate = until.toLocaleString(['zh-Zh'],options)
            setFormattedDate(formattedDate)
        }
    },[i18n.language])

    useEffect(()=>{
        if (subscriptionInfo) {
            setCommission(subscriptionInfo[2]?.commission)
            setMonthPrice(subscriptionInfo[2]?.month_price)
            setYearPrice(subscriptionInfo[2]?.year_price)
        }
    },[subscriptionInfo])

    const handlePrint = useReactToPrint({
        content: () => document.querySelector('.print_pro'),
        documentTitle: `Счет на оплату подписки TONNA Pro ${moment().format('DD.MM.yyyy')}`,
        onBeforeGetContent:()=>{
            return new Promise((resolve,reject) => {
                dispatch(createTransaction({ type: "pro",monthly:monthly})).then(res=>resolve(res)).catch(err=>reject(err))
            } )
        },
        removeAfterPrint:false
    })

    const handleIdSubscription=()=>{
            dispatch(fetchIdSubscription({type: "pro",monthly:monthly}))
    }

    return (
        <div className="subscription_card mobile_nitro" >
            <ProPayment token={orderId} invoiceNumber={GetInvoiceNumber(invoiceNumber)}/>
            <div className='subscription_title_container'>
                <span className="subscription_card_title blue_title">Pro</span>
                {active && <div className='subscription_active_status'>{t('Subscriptions.active')}
                    <img src={ActiveCheck} alt={''}/>
                </div>}
            </div>
            <div className='subscription_card_content'>
                <div className='subscription_card_about'>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{commission}% {t('Subscriptions.Nitro.first')}</span>
                        </div>
                        {/*<div className='about_items_container'>*/}
                        {/*    /!*<img src={markImg} alt=""/>*!/*/}
                        {/*    <span>{subscriptionInfo[2]?.responds} {t('Subscriptions.Nitro.second')}</span>*/}
                        {/*</div>*/}
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Pro.six')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Pro.seven')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.fifth')}
                                {t('Subscriptions.Pro.fifthStrong')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Pro.fourth')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.sixth')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Pro.second')}</span>
                        </div>
                        <div className='about_items_container' style={{marginBottom:'28px'}}>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Pro.first')}</span>
                        </div>
                    </div>
                </div>
            </div>
            {active ? <div className='subscription_card_subtitle date_pro'>{formattedDate} — {t('Subscriptions.lastDayOfSubscription')}</div> :
                <div className='subscription_subtitle_container pro_mobile' >
                <div className='subscription_card_price'>{monthly ? <b style={{margin:'0 30px 0 0',fontWeight:'700',fontSize: '20px'}}>
                        {monthPrice} ₽</b> : <b style={{margin:'0 28px 0 0',fontWeight:'700',fontSize: '20px'}}><strike
                    style={{marginRight:"4px"}}>529 200
                </strike>₽</b>}
                <b style={{fontSize: '20px',fontWeight:'700'}}>{!monthly && <>{yearPrice} ₽/{t('Subscriptions.Nitro.year')}</>}</b>
                </div>
                <button onClick={() => {
                handleIdSubscription()
                dispatch(increaseInvoiceNumber())
            }} style={{marginTop: '16px'}}
                className="chose_btn">{t('Subscriptions.Pro.btn')}
                </button>
                <button className='download_btn' onClick={()=>{handlePrint()}}>
            {t('Subscriptions.btnDownload')}
                </button>
                </div>
            }
        </div>
    );
};

export default Pro;