import React, {useEffect, useState} from 'react';
import ActiveCheck from '../../../../assets/newLanding/rates/activeCheck.svg'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createTransaction, fetchIdSubscription,increaseInvoiceNumber} from "../subscriptionsSlice";
import {useReactToPrint} from "react-to-print";
import moment from "moment";
import {GetInvoiceNumber} from "../../GetInvoiceNumber";
import StartPayment from "./StartPayment";

const Start = ({active,disabled,date}) => {
    const {t,i18n} = useTranslation()
    const dispatch = useDispatch()
    // const invoice = useSelector(state => state.walletSlice.wallet.invoice)
    const invoiceNumber = useSelector(state => state.subscriptionsSlice.invoiceId)
    const subscriptionInfo=useSelector(state=>state.subscriptionsSlice.subscriptionInfo)
    const monthly=useSelector(state =>state.subscriptionsSlice.monthly)
    const orderId=useSelector(state=>state.subscriptionsSlice.orderId)

    const [commission,setCommission]=useState('')
    const [responds,setResponds]=useState('')
    const [offers,setOffers]=useState('')
    const [monthPrice,setMonthPrice]=useState('')
    const [yearPrice,setYearPrice]=useState('')

    const [formattedDate,setFormattedDate]=useState('')

    useEffect(()=>{
        const until = new Date(date)
        const options = { month: 'long', day: 'numeric' }
        if (i18n.language === 'en') {
            const formattedDate = until.toLocaleString(['en-US'],options)
            setFormattedDate(formattedDate)
        }
        if (i18n.language === 'ru') {
            const formattedDate = until.toLocaleString(['ru-Ru'],options)
            setFormattedDate(formattedDate)
        }
        if (i18n.language === 'zh') {
            const formattedDate = until.toLocaleString(['zh-Zh'],options)
            setFormattedDate(formattedDate)
        }
    },[i18n.language])


    useEffect(()=>{
        if (subscriptionInfo) {
            setCommission(subscriptionInfo[0]?.commission)
            setResponds(subscriptionInfo[0]?.responds)
            setOffers(subscriptionInfo[0]?.offers)
            setMonthPrice(subscriptionInfo[0]?.month_price)
            setYearPrice(subscriptionInfo[0]?.year_price)
        }
    },[subscriptionInfo])

    const handlePrint = useReactToPrint({
        content: () => document.querySelector('.print_start'),
        documentTitle: `Счет на оплату подписки TONNA Start ${moment().format('DD.MM.yyyy')}`,
        onBeforeGetContent:()=>{
            return new Promise((resolve,reject) => {
                dispatch(createTransaction({ type: "start",monthly:monthly})).then(res=>resolve(res)).catch(err=>reject(err))
            } )
    },
        removeAfterPrint:false
    })

    const handleIdSubscription=()=>{
        dispatch(fetchIdSubscription({ type: "start",monthly:monthly}))
    }

    return (
        <div className="subscription_card" style={{opacity:disabled ? "0.3" : null}}>
            <StartPayment token={orderId} invoiceNumber={GetInvoiceNumber(invoiceNumber)}/>
            <div className='subscription_title_container'>
                <span className="subscription_card_title">Start</span>
                {active && !disabled && <div className='subscription_active_status'>{t('Subscriptions.active')}
                    <img src={ActiveCheck} alt={''}/>
                </div>}
            </div>
            <div className='subscription_card_content'>
                <div className='subscription_card_about'>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>

                            <span>{commission}% {t('Subscriptions.Nitro.first')}</span>
                        </div>
                        {/*<div className='about_items_container'>*/}
                        {/*    <span>1 {t('Subscriptions.Start.second')}</span>*/}
                        {/*</div>*/}
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            <span>{responds} {t('Subscriptions.Nitro.third')}</span>
                        </div>
                        <div className='about_items_container'>
                            <span>{t('Subscriptions.Pro.six')}</span>
                        </div>
                    </div>
                </div>
            </div>
            {active ? <div className='subscription_card_subtitle'>{formattedDate} — {t('Subscriptions.lastDayOfSubscription')}</div> :
                <div className='subscription_subtitle_container start_padding'>
                    <div className='subscription_card_price'>{monthly ? <b style={{marginRight:'29px',fontWeight:'700',fontSize: '20px'}}>
                            {monthPrice} ₽</b> : <b style={{marginRight:'29px',fontWeight:'700',fontSize: '20px'}}><strike
                        style={{marginRight:"4px"}}>68400
                    </strike>₽</b>}

                        <b style={{fontSize: '20px',fontWeight:'700'}}>{!monthly && <>{yearPrice} ₽/{t('Subscriptions.Nitro.year')}</>}</b>
                    </div>
                    <button onClick={() => {
                        handleIdSubscription()
                        dispatch(increaseInvoiceNumber())
                    }} style={{marginTop: '16px'}}
                            className="chose_btn">{t('Subscriptions.Start.btn')}
                    </button>
                    <button className='download_btn' onClick={()=>{
                        handlePrint();
                    }}>
                        {t('Subscriptions.btnDownload')}
                    </button>
                </div>}
        </div>
    );
};

export default Start;