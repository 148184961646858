import { combineReducers } from 'redux';
import newOfferSlice from '../components/Offers/NewOffer/newOfferSlice'
import offersSlice from '../components/Offers/offersSlice'
import offerSlice from '../components/Offers/Offer/offerSlice'
import verificationSlice from '../components/Verification/verificationSlice'
import walletSlice from '../components/Wallet/walletSlice'
import selectLocationSlice from '../components/Maps/SelectLocationMap/components/selectLocationSlice'
import homeSlice from '../components/Home/homeSlice'
import subscriptionsSlice from '../components/components/Subscriptions/subscriptionsSlice';
import notificationsSlice from "../components/Notifications/notificationsSlice";
import chatSlice from "../components/Chats/ChatSlice";
import authSlice from './authSlice';

const reducer = combineReducers({
  newOfferSlice,
  offersSlice,
  offerSlice,
  verificationSlice,
  walletSlice,
  selectLocationSlice,
  homeSlice,
  subscriptionsSlice,
  notificationsSlice,
  chatSlice,
  authSlice
});

export default reducer;
