import keycloak from '../Keycloak';
import history from "./history";
import { getConfig } from '../configs/config';

export const logoutUser = () => {
    document.cookie = "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "ga_=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    history.push('/')
    keycloak.logout();
};

// // костыль из апи
// // видимо этот способ позволяет сразу прикрепить нужную куку к домену
// export const login = () => {
//     window.location.href = `${getConfig().baseUrl}/oauth2/authorization/keycloak`;
// };

export const login = () => {
    return keycloak.login({
        redirectUri: window.location.href,
    });
};
