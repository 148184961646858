import React, { useState, useCallback } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as TelegramIcon } from '../../assets/icon/telegramIcon.svg';
import {NavLink} from "react-router-dom";
import TelegramHelper from "./telegramHelper";
import styles from './helperPopup.module.css'

const HelperComponent = React.memo(() => {
    const [popupVisible, setPopupVisible] = useState(false);
    const togglePopup = useCallback(() => {
        setPopupVisible(prev => !prev);
    }, []);

    return (
        <div className={styles.new_offer}>
            <button onClick={togglePopup} className={styles.helper_button} type="primary">
                <TelegramIcon />
            </button>
            <TelegramHelper setPopupVisible={setPopupVisible} popupVisible={popupVisible} />
            <NavLink to='/newoffer'>
                <Button className={styles.helper_button} type="primary"
                        shape="circle"
                        size='large'>
                    <PlusOutlined style={{color: '#615FFB'}}/>
                </Button>
            </NavLink>
        </div>
    );
});

export default HelperComponent;
