import React from 'react';
import moment from 'moment'
import {stringToNum} from "../../masks";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const rubles = require('rubles').rubles

const ProPayment = (props) => {
    const monthly=useSelector(state =>state.subscriptionsSlice.monthly)

    const {t} = useTranslation()
    const formatPrice = stringToNum(monthly ? '44100' : '423360')
    const textPrice = rubles(formatPrice)
    const date = new Date().getTime()

    return (
        <div style={{display: 'none'}}>
            <div style={{padding: '0 20px', maxWidth: 900, margin: '0 auto',height:'auto'}} className='print_pro'>
                <table width="100%" style={{fontFamily: 'Arial'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '68%', padding: '20px 0'}}>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div style={{display: 'flex', flexDirection: 'row'}}>

                    <table width="100%" border={2}
                           style={{borderCollapse: 'collapse', width: '100%', fontFamily: 'Arial'}} cellPadding={2}
                           cellSpacing={2}>
                        <tbody>
                        <tr style={{}}>
                            <td colSpan={2} rowSpan={2} style={{minHeight: '13mm', width: '105mm'}}>
                                <table width="100%" border={0} cellPadding={0} cellSpacing={0} style={{height: '13mm'}}>
                                    <tbody>
                                    <tr>
                                        <td valign="top">
                                            <div>{t('DownloadInvoice.filial')}<br/> {t('DownloadInvoice.bank')}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="bottom" style={{height: '3mm'}}>
                                            <div style={{fontSize: '10pt'}}>{t('DownloadInvoice.bankReceiver')}</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{minHeight: '7mm', height: 'auto', width: '25mm'}}>
                                <div>{t('DownloadInvoice.bik')}</div>
                            </td>
                            <td rowSpan={2} style={{verticalAlign: 'top', width: '60mm'}}>
                                <div style={{height: '7mm', lineHeight: '7mm', verticalAlign: 'middle'}}>042202847</div>
                                <div>30101810300000000847</div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: '25mm'}}>
                                <div>{t('DownloadInvoice.count')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{minHeight: '6mm', height: 'auto', width: '50mm'}}>
                                <div>{t('DownloadInvoice.inn')} 5906105146</div>
                            </td>
                            <td style={{minHeight: '6mm', height: 'auto', width: '55mm'}}>
                                <div>{t('DownloadInvoice.kpp')} 590601001</div>
                            </td>
                            <td rowSpan={2}
                                style={{minHeight: '19mm', height: 'auto', verticalAlign: 'top', width: '25mm'}}>
                                <div>{t('DownloadInvoice.count')}</div>
                            </td>
                            <td rowSpan={2}
                                style={{minHeight: '19mm', height: 'auto', verticalAlign: 'top', width: '60mm'}}>
                                <div>40702810223000075929</div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{minHeight: '13mm', height: 'auto'}}>
                                <table border={0} cellPadding={0} cellSpacing={0}
                                       style={{height: '13mm', width: '105mm'}}>
                                    <tbody>
                                    <tr>
                                        <td valign="top">
                                            <div>{t('DownloadInvoice.title')}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="bottom" style={{height: '3mm'}}>
                                            <div style={{fontSize: '10pt'}}>{t('DownloadInvoice.receiver')}</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div style={{fontWeight: 'bold', fontSize: '25pt', paddingLeft: '5px', fontFamily: 'Arial'}}>
                    {t('DownloadInvoice.invoicePayment')} {`№ t/${props.invoiceNumber}`} {t('DownloadInvoice.from')} {moment(date).format('DD.MM.YY')}</div>
                <br/>
                <div style={{backgroundColor: '#000000', width: '100%', fontSize: '1px', height: '2px'}}>&nbsp;</div>
                <table width="100%" style={{fontFamily: 'Arial'}}>
                    <br/>
                    <tbody>
                    <tr>
                        <td style={{width: '30mm', verticalAlign: 'top'}}>
                            <div style={{paddingLeft: '2px'}}>{t('DownloadInvoice.provider')}<br/>({t('DownloadInvoice.executor')}):</div>
                        </td>
                        <td>
                            <div style={{fontWeight: 'bold', paddingLeft: '2px'}}>
                                {t('DownloadInvoice.title')} {t('DownloadInvoice.address')} <br/>
                            </div>
                        </td>
                    </tr>
                    <br/>
                    <tr>
                        <td style={{width: '30mm', verticalAlign: 'top'}}>
                            <div style={{paddingLeft: '2px'}}>{t('DownloadInvoice.buyer')} <br/>({t('DownloadInvoice.customer')}):</div>
                        </td>
                        <td>
                            <div style={{fontWeight: 'bold', paddingLeft: '2px'}}>
                                {/*<span style={{ fontWeight: 'normal' }}>213245, Российская Федерация, г. ,  пр-кт, д.151 лит. А,<br /></span> */}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    <br/>
                </table>
                <table border={2} width="100%" cellPadding={2} cellSpacing={2}
                       style={{borderCollapse: 'collapse', width: '100%', fontFamily: 'Arial'}}>
                    <thead>
                    <tr>
                        <th style={{width: '13mm'}}>№</th>
                        <th>{t('DownloadInvoice.goods')}</th>
                        <th style={{width: '20mm'}}>{t('DownloadInvoice.quantity')}</th>
                        <th style={{width: '17mm'}}>{t('DownloadInvoice.unit')}</th>
                        <th style={{width: '27mm'}}>{t('DownloadInvoice.price')}</th>
                        <th style={{width: '27mm'}}>{t('DownloadInvoice.sum')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{width: '13mm'}}>1</td>
                        <td>{t('DownloadInvoice.subscription')} TONNA Pro: {props.token}</td>
                        <td style={{width: '20mm'}}>1</td>
                        <td style={{width: '17mm'}}>{t('DownloadInvoice.pc')}</td>
                        <td style={{width: '27mm', textAlign: 'center'}}>{monthly ? '44,100.00' : '423,360.00'}</td>
                        <td style={{width: '27mm', textAlign: 'center'}}>{monthly ? '44,100.00' : '423,360.00'}</td>
                    </tr>
                    </tbody>
                </table>
                <table style={{fontFamily: 'Arial'}} border={0} width="100%" cellPadding={1} cellSpacing={1}>
                    <tbody>
                    <tr>
                        <td/>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>{t('DownloadInvoice.total')}</td>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'center'}}>{monthly ? '44,100.00' : '423,360.00'}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>{t('DownloadInvoice.taxFree')}</td>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'center'}}>-</td>
                    </tr>
                    <tr>
                        <td/>
                        <td style={{width: '37mm', fontWeight: 'bold', textAlign: 'right'}}>{t('DownloadInvoice.totalPay')}</td>
                        <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'center'}}>{monthly ? '44,100.00' : '423,360.00'}</td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <div style={{fontFamily: 'Arial'}}>
                    {t('DownloadInvoice.items')} {monthly ? '44,100.00' : '423,360.00'} {t('DownloadInvoice.rub')}<br/>
                    <b>{textPrice}</b></div>
                <br/>
                <div style={{fontFamily: 'Arial', fontSize: '10pt'}}>
                    {t('DownloadInvoice.termsOfService')}<br/>
                    <span>1. {t('DownloadInvoice.termsOfServiceOne')}</span><br/>
                    <span>2. {t('DownloadInvoice.termsOfServiceTwo')}</span><br/>
                    <span>3. {t('DownloadInvoice.termsOfServiceThree')}
                    </span><br/>
                    <span>4. {t('DownloadInvoice.termsOfServiceFour')}</span>
                </div>
                <br/>
                <div style={{backgroundColor: '#000000', width: '100%', fontSize: '1px', height: '2px'}}>&nbsp;</div>
                <br/>

                <br/>
                <div style={{
                    background: 'none',
                    backgroundRepeat: 'no-repeat',
                    padding: '30px 10px',
                    width: '400px',
                    height: '250px'
                }}>
                    <div>{t('DownloadInvoice.supervisor')} ______________________</div>
                    <br/> <br/><br/>
                    <div>{t('DownloadInvoice.accountant')} ______________________</div>
                    <br/>
                    <br/>
                </div>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
        </div>
    );
}

export default ProPayment;