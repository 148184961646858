import React, {useState} from 'react'
import {Form, Input, Select, DatePicker, Tooltip} from 'antd'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import GoBack from '../components/GoBack/GoBack'
import {fetchBeneficiary, sendBeneficiary} from './verificationSlice'
import history from '../../utils/history'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Loader from '../components/Loader'
import {QuestionCircleOutlined} from '@ant-design/icons'
import {Helmet} from "react-helmet";

function Verification() {
    // const [isModalVisible, setIsModalVisible] = useState(false)
    const [type, setType] = useState('ip')
    const [form] = Form.useForm()
    const {t} = useTranslation()
    const isFailed = useSelector(state => state.verificationSlice.sendBeneficiary.error.failed)
    const isLoaded = useSelector(state => state.verificationSlice.sendBeneficiary.loaded)
    const dispatch = useDispatch()
    const {Option} = Select
    const verifyType = useParams().category


    const onReset = () => {
        form.resetFields()
    }

    const selectHandler = (e) => {
        setType(e)
    }

    const handleSubmit = (formData) => {
        const inn = formData.inn
        delete formData.inn
        const form = {
            beneficiary_data: type === 'ul'
                ? {
                    kpp: formData.kpp || ' ',
                    name: formData.name
                }
                : {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    middle_name: formData.middle_name || ' '
                },
            inn: inn
        }

        // const form = {
        //   beneficiary_data: formData,
        //   inn: inn
        // }
        // в аргументах был токен
        dispatch(sendBeneficiary({type, form, verifyType})
        )
            .then((params) => {
                if (!params.error) {
                    dispatch(fetchBeneficiary({verifyType, isFailed}))
                        .then((params) => {
                            onReset()
                            if (verifyType !== 'tbank') {
                                history.goBack()
                            } else {
                                history.push('/wallet')
                            }
                        })
                }
            })
    }

    return (
        <div className="Verification main">
            {!isLoaded ? <Loader/> :
                <div className="content-wrapper">
                    <GoBack prevPageName={t('Verification.goBack')}/>
                    <Helmet>
                        <title>{t('Verification.verification')}</title>
                    </Helmet>
                    <h1 className="title">{t('Verification.verification')}</h1>
                    <Form className="new-req-form" onFinish={handleSubmit} form={form} size="large"
                          name="Оставить заявку"
                          initialValues={{}}
                          scrollToFirstError>
                        <Form.Item>
                            <Select value={type} onSelect={selectHandler} placeholder={t('Verification.userType')}>
                                <Option value="ip">{t('Verification.ip')}</Option>
                                <Option value="ul">{t('Verification.entity')}</Option>
                                {/* <Option value="fl">{t('Verification.individual')}</Option> */}
                            </Select>
                        </Form.Item>
                        <Form.Item name="inn">
                            <Input requered suffix={<Tooltip
                                title={t('Verification.innTooltip')}><QuestionCircleOutlined/></Tooltip>} required
                                   placeholder={t('Verification.TaxpayerNum')}/>
                        </Form.Item>
                        {type === 'ip' || type === 'fl' ?
                            <>
                                <Form.Item name="lastName">
                                    <Input required placeholder={t('Verification.lastName')}/>
                                </Form.Item>
                                <Form.Item name="firstName">
                                    <Input required placeholder={t('Verification.name')}/>
                                </Form.Item>
                                <Form.Item name="middleName">
                                    <Input placeholder={t('Verification.middleName')}/>
                                </Form.Item>
                            </>
                            : null}

                        {type === 'ul'  ?
                            <>
                                <Form.Item name="kpp">
                                    <Input placeholder={t('Verification.rrc')}/>
                                </Form.Item>
                                <Form.Item name="name">
                                    <Input required placeholder={t('Verification.entityName')}/>
                                </Form.Item>
                            </>
                            : null}
                        {type === 'ip'  || type === 'ul' ? <>
                            <Form.Item name="email">
                                <Input required placeholder={t('Map.giftMyEmail')}/>
                            </Form.Item>
                            <Form.Item name="phoneNumber">
                                <Input required placeholder={t('Lending.modalSecondInput')}/>
                            </Form.Item>

                            {/*<Form.Item name="birthPlace">*/}
                            {/*    <Input required placeholder={t('Verification.placeOfBirth')}/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item name="registrationAddress">*/}
                            {/*    <Input required placeholder={t('Verification.registrationAddress')}/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item name="passportNumber">*/}
                            {/*    <Input required placeholder={t('Verification.passportNumber')}/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item name="passportSeries">*/}
                            {/*    <Input required placeholder={t('Verification.passportSeries')}/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item name="passportDate">*/}
                            {/*    <DatePicker required placeholder={t('Verification.dateOfIssue')}/>*/}
                            {/*</Form.Item>*/}
                        </> : null}
                        <Form.Item className="new-req-form_btn-container">
                            <button disabled={!isLoaded} className="btn" htmlType="submit"
                                    type="primary">{t('Verification.send')}</button>
                        </Form.Item>
                    </Form>
                </div>
            }
        </div>
    )
}

export default Verification
