import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {api} from "./api";


export const ACCESS_TOKEN_KEY = "access_token";

const initialState = () => {
    return {
        isLoggedIn:!!localStorage.getItem(ACCESS_TOKEN_KEY),
        userInfo:null,
        date:''
    }
}
export const fetchUserInfo = createAsyncThunk(
    'beneficiary/fetchUserInfo',
    async () => {
        try {
            const response = await api.get(`/private/user`)
            return response.data
        } catch (err) {
            console.log(err)
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState(),
    extraReducers: {
        setLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        [fetchUserInfo.pending]: (state) => {

        },
        [fetchUserInfo.fulfilled]: (state, action) => {
            state.userInfo = action.payload
        },
        [fetchUserInfo.rejected]: (state, action) => {

        },
    }
})
export const {setLoggedIn} = authSlice.actions

export default authSlice.reducer